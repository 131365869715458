import React, { Component } from 'react'

import { inject, observer } from 'mobx-react'
import Button from '@material-ui/core/Button';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs } from '@material-ui/core';
import { Transition } from 'react-transition-group';
import { getAllArticlesByOrderId, setReturnStatusFinished, getAllReturns, deleteReturn } from '../../utils/APIUtils'
import { AddBox, ArrowDownward, CachedOutlined, Check, ChevronRight, Clear, DeleteOutline, Edit, FilterList, FirstPage, LastPage, Remove, SaveAlt, Search, ViewColumn } from '@material-ui/icons';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';

const tableIcons = {
    Add: React.forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: React.forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: React.forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: React.forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: React.forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: React.forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: React.forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: React.forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: React.forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: React.forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: React.forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: React.forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    ResetSearch: React.forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: React.forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: React.forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: React.forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: React.forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

@inject("appStore")
@observer
export default class Submitted extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isArticleViewOpen: false,
            isArticleLoading: true,
            isSetStatusOpen: false,
            isDeleteOpen: false,
        }
    }

    componentDidMount() {

        
    }

    handleArticleViewClickOpen = (orderId) => {
        const store = this.props.appStore
        this.setState({isArticleLoading:true})

        getAllArticlesByOrderId(orderId, store.token.accessToken, store.lang).then(
            (response) => {
                store.resetCurrentArticles()
                console.log(response.data)
                store.setCurrentArticles(response.data)
            },
            (error) => {
                store.enqueueSnackbar({
                    message: error.response.data.message,
                    options: {
                        variant: 'error',
                    },
                });
            }
        ).catch((error) => {
            store.enqueueSnackbar({
                message: "Unbekannter Fehler aufgetreten. Bitte melden Sie sich beim Kundenservice",
                options: {
                    variant: 'error',
                },
            });
            console.log(error)
        })
        this.setState({isArticleViewOpen: true})
        this.setState({isArticleLoading:false})
    };
    
    handleArticleViewClose = () => {
        this.setState({
            isArticleViewOpen: false,
            isArticleLoading: true,
        })  
    };

    handleSetStatusSubmit = () => {
        var store = this.props.appStore
        if(store.setCurrentReturn && store.token) {
            setReturnStatusFinished(store.currentReturn.orderId, store.token.accessToken, store.lang).then(
                (response) => {
                    getAllReturns(store.token.accessToken, store.lang).then(
                        (response) => {
                            store.resetReturns()
                            store.setReturns(response.data)
                            this.setState({isSetStatusOpen:false})
                        },
                        (error) => {
                            store.enqueueSnackbar({
                                message: error.response.data.message,
                                options: {
                                    variant: 'error',
                                },
                            });
                            this.setState({isSetStatusOpen:false})
                        }
                    ).catch(
                        (error) => {
                            store.enqueueSnackbar({
                                message: "Unbekannter Fehler aufgetreten. Bitte melden Sie sich beim Kundenservice",
                                options: {
                                    variant: 'error',
                                },
                            });
                            console.log(error)
                            this.setState({isSetStatusOpen:false})
                        }
                    )
                    store.enqueueSnackbar({
                        message: "Die Retoure ist fertiggestellt. Sie wird im Laufe des Tages archiviert.",
                        options: {
                            variant: 'success',
                        },
                    });
                    this.setState({isSetStatusOpen:false})
                },
                (error) => {
                    store.enqueueSnackbar({
                        message: error.response.data.message,
                        options: {
                            variant: 'error',
                        },
                    });
                    this.setState({isSetStatusOpen:false})
                }
            ).catch(
                (error) => {
                    store.enqueueSnackbar({
                        message: "Unbekannter Fehler aufgetreten. Bitte melden Sie sich beim Kundenservice",
                        options: {
                            variant: 'error',
                        },
                    });
                    console.log(error)
                    this.setState({isSetStatusOpen:false})
                }
            )
        }
        
    }

    handleSetStatusClickOpen = (orderId) => {
        var store = this.props.appStore
        var returns = store.returns
        for(var i = 0; i < returns.length; i++) {
            if(returns[i].orderId === orderId) {
                store.setCurrentReturn(returns[i])
            } 
        }

        this.setState({isSetStatusOpen: true})
    };
    

    handleSetStatusClose = () => {
        this.setState({isSetStatusOpen: false})  
    };

    handleDeleteOpen = (orderId) => {
        var store = this.props.appStore
        var returns = store.returns
        for(var i = 0; i < returns.length; i++) {
            if(returns[i].orderId === orderId) {
                store.setCurrentReturn(returns[i])
            } 
        }

        this.setState({isDeleteOpen: true})
    };

    handleDeleteClose = () => {
        this.setState({isDeleteOpen: false})
    }

    handleDeleteSubmit = () => {
        var store = this.props.appStore
        if(store.setCurrentReturn && store.token) {
            deleteReturn(store.currentReturn.orderId, store.token.accessToken, store.lang).then(
                (response) => {
                    getAllReturns(store.token.accessToken, store.lang).then(
                        (response) => {
                            store.resetReturns()
                            store.setReturns(response.data)
                            
                            this.setState({isDeleteOpen:false})
                        },
                        (error) => {
                            store.enqueueSnackbar({
                                message: error.response.data.message,
                                options: {
                                    variant: 'error',
                                },
                            });
                            
                            this.setState({isDeleteOpen:false})
                        }
                    ).catch(
                        (error) => {
                            store.enqueueSnackbar({
                                message: "Unbekannter Fehler aufgetreten. Bitte melden Sie sich beim Kundenservice",
                                options: {
                                    variant: 'error',
                                },
                            });
                            console.log(error)
                            this.setState({isDeleteOpen:false})
                        }
                    )
                    store.enqueueSnackbar({
                        message: "Die Retoure wurde erfolgreich gelöscht.",
                        options: {
                            variant: 'success',
                        },
                    });
                    this.setState({isDeleteOpen:false})
                },
                (error) => {
                    store.enqueueSnackbar({
                        message: error.response.data.message,
                        options: {
                            variant: 'error',
                        },
                    });
                    this.setState({isDeleteOpen:false})
                }
            ).catch(
                (error) => {
                    store.enqueueSnackbar({
                        message: "Unbekannter Fehler aufgetreten. Bitte melden Sie sich beim Kundenservice",
                        options: {
                            variant: 'error',
                        },
                    });
                    console.log(error)
                    this.setState({isDeleteOpen:false})
                }
            )
        }
    }

    render() {
        var store = this.props.appStore
        var returns = []

        {store.returns.map((row, index) =>  
                        
            {if (row.returnStatus === "SUBMITTED" || row.returnStatus === "PRE_TRANSIT" || row.returnStatus === "TRANSIT" || row.returnStatus === "DELIVERED" || row.returnStatus === "FINISHED" || row.returnStatus === "READY_TO_ARCHIVING") {
                returns.push(
                    {   orderId: row.orderId, trackingCode: row.myLabel.shipmentNumber, date: new Intl.DateTimeFormat('de-DE').format(new Date(row.createdAt)), email: row.email, firstname: row.firstname, lastname: row.lastname, postcode: row.postcode, 
                        street: row.street, type: row.returnType, status: row.returnStatus, 
                        details: <Button onClick={(e) => this.handleArticleViewClickOpen(row.orderId)} variant="contained" color="default">Artikel einsehen</Button>, 
                        setStatus: <Button onClick={(e) => this.handleSetStatusClickOpen(row.orderId)} variant="contained" color="default">Gutschrift abgeschlossen</Button>,
                        delete: <Button
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<DeleteIcon />}
                                    onClick={(e) => this.handleDeleteOpen(row.orderId)}
                                >
                                    Löschen
                                </Button> 
                    })
            }}
        )}

        var article;
        if(this.state.isArticleLoading) {
            article = "Article is loading"
        } else {
            article = (
                <TableContainer>
                            <Table>
                                <TableHead>
                                <TableRow>
                                    <TableCell>Anzahl der Rückgaben</TableCell>
                                    <TableCell align="right">Artikel</TableCell>
                                    <TableCell align="right">Stückpreis</TableCell>
                                    <TableCell align="right">Grund</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {store.currentArticles.map((row, index) => (
                                    <TableRow key={row.id}>
                                    <TableCell align="right">
                                        <Select 
                                            disabled
                                            labelId="number-of-return" 
                                            id="number-of-return" 
                                            value={row.numberOfReturns}>
                                            {Array.from(Array(row.quantity+1).keys()).map((row, index) => (
                                                <MenuItem key={index} value={index}>{index}</MenuItem>
                                            ))}
                                        </Select>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.articleName}
                                    </TableCell>
                                    <TableCell align="right">{row.price}</TableCell>
                                    <TableCell align="right">
                                        <Select 
                                            disabled
                                            labelId="number-of-return" 
                                            id="number-of-return" 
                                            value={row.returnReason}
                                        >
                                            <MenuItem value={"NOCH_NICHTS"}>Bitte ausfüllen</MenuItem>
                                            <MenuItem value={"BRUCH"}>Bruch</MenuItem>
                                            <MenuItem value={"KRATZER"}>Kratzer</MenuItem>
                                            <MenuItem value={"SCHLECHTE_QUALITAET"}>Schlechte Qualität</MenuItem>
                                            <MenuItem value={"FALSCHE_LIEFERUNG"}>Falsche Lieferung</MenuItem>
                                            <MenuItem value={"SONSTIGES"}>Ich möchte keine Angaben machen</MenuItem>
                                        </Select>
                                    </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                            </TableContainer>
            )
        }

                return (
                    <div>
                        
                            <MaterialTable
                                title="Laufende Retouren"
                                columns={[
                                    { title: 'Bestellnummer', field: 'orderId' },
                                    { title: 'Trackingcode', field: 'trackingCode' },
                                    { title: 'Eingang', field: 'date' },
                                    { title: 'Email', field: 'email'},
                                    { title: 'Vorname', field: 'firstname'},
                                    { title: 'Nachname', field: 'lastname'},
                                    { title: 'Postleitzahl', field: 'postcode'},
                                    { title: 'Straße', field: 'street'},
                                    { title: 'Art der Retoure', field: 'type'},
                                    { title: 'Retourenstatus', field: 'status'},
                                    { title: 'Artikeldetails', field: 'details'},
                                    { title: 'Retourenstatus setzen', field: 'setStatus'},
                                    { title: 'Retoure löschen', field: 'delete'}
                                ]}
                                data={returns}        
                                options={{
                                    search: true,
                                    paging: false
                                }}
                                />
                    <Dialog
                        open={this.state.isArticleViewOpen}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={this.handleArticleViewClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title">Infos zu den retourierten Artikel</DialogTitle>
                        <DialogContent>
                            {article}
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={this.handleArticleViewClose} color="primary">
                            Schließen
                        </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={this.state.isSetStatusOpen}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={this.handleSetStatusClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title">Retoure fertigstellen</DialogTitle>
                        <DialogContent>
                            Das Bestätigen des Buttons bewirkt, dass die Retoure komplett abgefertig ist. (Versand, Angekommen und Gutgeschrieben)
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={this.handleSetStatusSubmit} color="primary">
                            Bestätigen
                        </Button>
                        <Button onClick={this.handleSetStatusClose} color="primary">
                            Schließen
                        </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        style={{backgroundColor: '#ff8882'}}
                        open={this.state.isDeleteOpen}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={this.handleDeleteClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title">Retoure löschen</DialogTitle>
                        <DialogContent>
                            Das Bestätigen bewirkt, dass sämlichte Daten zur Retoure gelöscht werden. <br/>
                            Abgefertigte Retouren sollten in der Regel nicht gelöscht werden!
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={this.handleDeleteSubmit} color="primary">
                            Bestätigen
                        </Button>
                        <Button onClick={this.handleDeleteClose} color="primary">
                            Schließen
                        </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                

                )
           
            
    }
}

