import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    control: {
      padding: theme.spacing(2),
    },
  }));

export default function Footer() {
    const [spacing, setSpacing] = React.useState(2);
    const classes = useStyles();
    const [t, i18n] = useTranslation('common');


    return (
        <footer className={classes.root}>
            <Container>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container justify="center" spacing={5}>
                        <Grid item>
                        <a className="link underline" href={"https://www.edzard-shop.com/"} target="__blank">{t('link.shop')}</a>
                        </Grid>
                        <Grid item>
                            <a className="link" href={"https://www.edzard.com/impressum"} target="__blank">{t('link.impress')}</a>
                        </Grid>
                        <Grid item>
                            <a className="link" href={"https://edzard-home.de/datenschutz"} target="__blank">{t('link.data-privacy')}</a>
                        </Grid>
                        <Grid item>
    <a className="link" href={"https://edzard-home.de/agb"} target="__blank">{t('link.tos')}</a>
                        </Grid>
                        <Grid item>
                            <a className="link" href={"https://edzard-home.de/widerrufsrecht"} target="__blank">{t('link.revocation')}</a>
                        </Grid>
                        <Grid item>
                            <a className="link" href={"#"} id="changePreferences">{t('link.cookie')}</a>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            </Container>
        </footer>
    )
}
