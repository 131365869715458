import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Grid } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

class NotFound extends Component {
    render() {
        const {t} = this.props;
        return (
            <div className="container">
                <Container>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        >
                        <h1 className="title">
                            {t('error.404')}
                        </h1>
                        <div className="desc">
                            {t('error.404-message')}
                        </div>
                        <Link className="link mt-16" to="/">
                            <Button variant="contained" color="primary" href="#contained-buttons">
                                Zur Startseite
                            </Button>
                        </Link>
                    </Grid>
                </Container>
            </div>

        );
    }
}

export default withTranslation("common")(NotFound);