import React, { Component, useEffect } from 'react'
import { AppBar, Toolbar, Button, Typography, Grid } from '@material-ui/core';

import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { withSnackbar } from 'notistack';

const Header = inject("appStore")(observer((props) => {
    const [t, i18n] = useTranslation('common');

    console.log(props.appStore.lang);

    var languageButton;     
    if(props.appStore.lang === 'de') {
        languageButton = <Button className="ml-16 mt-8 mb-8" onClick={(e) => handleLanguageToEn(e)} color="secondary" variant="contained">English</Button>
    } else if(props.appStore.lang === 'en') {
        languageButton = <Button className="ml-16 mt-8 mb-8" onClick={(e) => handleLanguageToDe(e)} color="secondary" variant="contained">Deutsch</Button>
    }
    function handleLanguageToDe(e) {
        var store = props.appStore
        store.lang = 'de'
        i18n.changeLanguage('de')
    }

    function handleLanguageToEn(e) {
        var store = props.appStore
        store.lang = 'en'
        i18n.changeLanguage('en')
    }

        return (
                <AppBar className="header" position="static">
                        <Toolbar>
                            <Grid
                                container
                                direction="row"
                                justify="space-between"
                                alignItems="center"
                            >
                                
                                <Link className="link" to="/">
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="center"
                                >
                                    <img className="logo mb-16 mt-16" src={process.env.PUBLIC_URL + "/logo.png"} alt="Logo" />
                                    <div className="ml-16">
                                        <Typography variant="subtitle2" ml={2}>
                                            {t('return.portal')}
                                        </Typography>   
                                    </div>
                                </Grid>
                                    
                                </Link>
                                <Grid 
                                    item
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="center"
                                    >
                                    <Link className="link" to="/tracking"><Button className="ml-16 mt-8 mb-8" color="secondary" variant="contained">{t('link.tracking')}</Button></Link>
                                    <a className="link" href={"mailto:service@edzard.com"}><Button className="ml-16 mt-8 mb-8" color="secondary" variant="contained">service@edzard.com</Button></a>
                                    {languageButton}
                                </Grid>
                                
                        </Grid>
                    </Toolbar>
                </AppBar>
        )
}))
export default withSnackbar(Header);

