import React, { Component } from 'react'

import { inject, observer } from 'mobx-react'
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { signin } from '../../utils/APIUtils';
import history from '../../history';
  
@inject("appStore")
@observer
export default class Login extends Component {
    constructor(props) {
        super(props)

    }

    componentDidMount() {
        var store = this.props.appStore
        store.resetLogin()
    }

    componentWillUnmount() {
        
    }

    handleLogin = (e) => {
        var store = this.props.appStore
        e.preventDefault()
        var request = { username: store.username, password: store.password }

        signin(request, store.lang).then(
            (response) => {
                store.setToken(response.data)
                history.push("/backend")
            }, 
            (error) => {
                    store.enqueueSnackbar({
                        message: "Die Anmeldedaten sind fehlerhaft.",
                        options: {
                            variant: 'error',
                        },
                    });
            }
        ).catch((error) => {
            store.enqueueSnackbar({
                message: "Unbekannter Fehler aufgetreten. Bitte melden Sie sich beim Kundenservice",
                options: {
                    variant: 'error',
                },
            });
            console.log(error)
        })


    }

    handleEmailChange = (e) => {
        e.preventDefault()
        var store = this.props.appStore
        store.username = e.target.value
    }

    handlePasswordChange = (e) => {
        e.preventDefault()
        var store = this.props.appStore
        store.password = e.target.value
    }


    render() {
        var store = this.props.appStore
        return (
            <div className="container">
                    <Container component="main" maxWidth="xs">
                        <CssBaseline />
                        <div>
                            <Typography component="h1" variant="h5">
                            Backend Login
                            </Typography>
                            <form noValidate>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="E-Mail Adresse"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                value={store.username}
                                onChange={this.handleEmailChange}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Passwort"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={store.password}
                                onChange={this.handlePasswordChange}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={this.handleLogin}
                            >
                                Einloggen
                            </Button>
                            </form>
                        </div>
                        </Container>
            </div>
        )
    }
}
