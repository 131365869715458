import React, { Component } from 'react'

import { inject, observer } from 'mobx-react'
import Button from '@material-ui/core/Button';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs } from '@material-ui/core';
import { Transition } from 'react-transition-group';
import { getAllArchivedArticlesById, setReturnStatusFinished, getAllArchivedReturns } from '../../utils/APIUtils'
import { AddBox, ArrowDownward, CachedOutlined, Check, ChevronRight, Clear, DeleteOutline, Edit, FilterList, FirstPage, LastPage, Remove, SaveAlt, Search, ViewColumn } from '@material-ui/icons';
import MaterialTable from 'material-table';

const tableIcons = {
    Add: React.forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: React.forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: React.forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: React.forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: React.forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: React.forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: React.forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: React.forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: React.forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: React.forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: React.forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: React.forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    ResetSearch: React.forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: React.forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: React.forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: React.forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: React.forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

@inject("appStore")
@observer
export default class Archived extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isArticleViewOpen: false,
            isArticleLoading: true
        }
    }

    componentDidMount() {

        
    }

    handleArticleViewClickOpen = (id) => {
        const store = this.props.appStore
        this.setState({isArticleLoading:true})

        getAllArchivedArticlesById(id, store.token.accessToken, store.lang).then(
            (response) => {
                console.log(response.data)
                store.resetCurrentArticles()
                store.setCurrentArticles(response.data)
                this.setState({isArticleLoading:false})
            },
            (error) => {
                store.enqueueSnackbar({
                    message: error.response.data.message,
                    options: {
                        variant: 'error',
                    },
                });
            }
        ).catch((error) => {
            store.enqueueSnackbar({
                message: "Unbekannter Fehler aufgetreten. Bitte melden Sie sich beim Kundenservice",
                options: {
                    variant: 'error',
                },
            });
            console.log(error)
        })
        this.setState({isArticleViewOpen: true})
        this.setState({isArticleLoading:false})
    };
    
    handleArticleViewClose = () => {
        this.setState({
            isArticleViewOpen: false,
            isArticleLoading: true,
        })  
    };

    render() {
        var store = this.props.appStore
        var returns = []

        {store.archivedReturns.map((row, index) =>  
                        
            {if (row.returnStatus === "SUBMITTED" || row.returnStatus === "PRE_TRANSIT" || row.returnStatus === "TRANSIT" || row.returnStatus === "DELIVERED" || row.returnStatus === "FINISHED" || row.returnStatus === "READY_TO_ARCHIVING") {
                returns.push({ orderId: row.orderId, date: new Intl.DateTimeFormat('de-DE').format(new Date(row.createdAt)), email: row.email, firstname: row.firstname, lastname: row.lastname, postcode: row.postcode, 
                    street: row.street, type: row.returnType, status: row.returnStatus, 
                        details: <Button onClick={(e) => this.handleArticleViewClickOpen(row.id)} variant="contained" color="default">Artikel einsehen</Button> })
            }}
        )}

        var article;
        if(this.state.isArticleLoading) {
            article = "Article is loading"
        } else {
            article = (
                <TableContainer>
                            <Table>
                                <TableHead>
                                <TableRow>
                                    <TableCell>Anzahl der Rückgaben</TableCell>
                                    <TableCell align="right">Artikel</TableCell>
                                    <TableCell align="right">Stückpreis</TableCell>
                                    <TableCell align="right">Grund</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {store.currentArticles.map((row, index) => (
                                    <TableRow key={row.id}>
                                    <TableCell align="right">
                                        <Select 
                                            disabled
                                            labelId="number-of-return" 
                                            id="number-of-return" 
                                            value={row.numberOfReturns}>
                                            {Array.from(Array(row.quantity+1).keys()).map((row, index) => (
                                                <MenuItem key={index} value={index}>{index}</MenuItem>
                                            ))}
                                        </Select>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.articleName}
                                    </TableCell>
                                    <TableCell align="right">{row.price}</TableCell>
                                    <TableCell align="right">
                                        <Select 
                                            disabled
                                            labelId="number-of-return" 
                                            id="number-of-return" 
                                            value={row.returnReason}
                                        >
                                            <MenuItem value={"NOCH_NICHTS"}>Bitte ausfüllen</MenuItem>
                                            <MenuItem value={"BRUCH"}>Bruch</MenuItem>
                                            <MenuItem value={"KRATZER"}>Kratzer</MenuItem>
                                            <MenuItem value={"SCHLECHTE_QUALITAET"}>Schlechte Qualität</MenuItem>
                                            <MenuItem value={"FALSCHE_LIEFERUNG"}>Falsche Lieferung</MenuItem>
                                            <MenuItem value={"SONSTIGES"}>Ich möchte keine Angaben machen</MenuItem>
                                        </Select>
                                    </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                            </TableContainer>
            )
        }

                return (
                    <div>
                            <MaterialTable
                                title="Abgeschlossene Retouren"
                                columns={[
                                    { title: 'Bestellnummer', field: 'orderId' },
                                    { title: 'Eingang', field: 'date' },
                                    { title: 'Email', field: 'email'},
                                    { title: 'Vorname', field: 'firstname'},
                                    { title: 'Nachname', field: 'lastname'},
                                    { title: 'Postleitzahl', field: 'postcode'},
                                    { title: 'Straße', field: 'street'},
                                    { title: 'Art der Retoure', field: 'type'},
                                    { title: 'Retourenstatus', field: 'status'},
                                    { title: 'Artikeldetails', field: 'details'},
                                ]}
                                data={returns}        
                                options={{
                                    search: true,
                                    paging: false
                                }}
                                />
                    <Dialog
                        open={this.state.isArticleViewOpen}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={this.handleArticleViewClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title">Infos zu den retourierten Artikel</DialogTitle>
                        <DialogContent>
                            {article}
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={this.handleArticleViewClose} color="primary">
                            Schließen
                        </Button>
                        </DialogActions>
                    </Dialog>
                    
                </div>
                

                )
           
            
    }
}

