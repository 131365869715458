import { observable, action, toJS, autorun, extendObservable } from "mobx";
import { observer } from "mobx-react";
import { persist } from "mobx-persist";

/**
function autoSave(store, save) {
    let firstRun = true;
    autorun(() => {
      // This code will run every time any observable property
      // on the store is updated.
      const json = JSON.stringify(toJS(store));
      if (!firstRun) {
        save(json);
      }
      firstRun = false;
    });
  }
 */



class AppStore {

    constructor() {
        /**this.load()
        autoSave(this, this.save.bind(this)) */
    }

    @persist('object') @observable lang = 'de'

    @persist('object')  @observable isUp = true;

    //Authentication
    @persist('object') @observable username = "";
    @persist('object') @observable password = "";
    @persist('object') @observable token = {};

    @action resetLogin() {
        this.username = ""
        this.password = ""
        this.token = {}
    }

    //Return & Tracking Form
    @persist('object') @observable orderIdInput = -1
    @persist('object') @observable postcodeInput = ""
    @persist('object') @observable emailInput = ""
    @persist('object') @observable returnTypeInput = "NOCH_NICHTS"
    @persist('object') @observable dataPrivacyChecked = false
    
    @persist('object') @observable orderRequest = {}

    @persist('object') @observable orderResponse = {}
    @persist('list') @observable articles = []

    @action resetInputs() {
        this.orderIdInput = -1
        this.postcodeInput = ""
        this.emailInput = ""
        this.returnTypeInput = "NOCH_NICHTS"  
        this.dataPrivacyChecked = false
    }

    @action resetOrder() {
        this.orderRequest = {}
        this.orderResponse = {}
    }

    @action resetArticles() {
        this.articles = []
    }

    //Tracking
    @persist('object') @observable label = {}

    @persist('object') @observable trackingOrderId = -1
    @persist('object') @observable trackingPostcode = ""
    @persist('object') @observable tracking = {}

    @action resetLabelAndTracking() {
        this.label = {}
        this.trackingOrderId = -1
        this.trackingPostCode = ""
        this.tracking = {}
    }

    //Backend
    @persist('list') @observable returns = []
    @persist('list') @observable archivedReturns = []
    @persist('list') @observable currentArticles = []
    @persist('object') @observable currentReturn = {}

    @action resetReturns() {
        this.returns = []
    }

    @action resetArchivedReturns() {
        this.archivedReturns = []
    }

    @action resetCurrentArticles() {
        this.currentArticles = []
    }

    //Email Activation
    @persist('object') @observable activationToken = ""
    

    ///////////////// Setters

    @action setOrderResponse(data) {

        if(data) {
            this.orderResponse = {
                id: data.id,
                customerId: data.customerId,
                orderId: data.orderId,
                firstname: data.firstname,
                lastname: data.lastname,
                street: data.street,
                postcode: data.postcode,
                city: data.city,
                country: data.country,
                orderDate: data.orderDate,
                returnType: data.returnType,
                articles: []
            }
        } else {
            console.log("Daten setzen fehlgeschlagen")
        }
        
    }

    @action setArticles(data) {

        if(data) {

            for(var i = 0; i < data.length; i++) {

                this.articles[i] = {
                    id: data[i].id,
                    articleId: data[i].articleId,
                    articleName: data[i].articleName,
                    price: data[i].price,
                    quantity: data[i].quantity,
                    returnReason: data[i].returnReason,
                    numberOfReturns: data[i].numberOfReturns,
                    articleStatusId: data[i].articleStatusId
                }
    
            }
        } else {
            console.log("Daten setzen fehlgeschlagen")
        }

    }

    @action setArticlesReason(value, index) {
        console.log(value)
        this.articles[index].returnReason = value
    }

    @action setArticlesNumberOfReturns(value, index) {
        this.articles[index].numberOfReturns = value
    }

    @action setLabel(data) {
        if(data) {
            this.label = {
                id: data.id,
                labelData: data.labelData
            }
        } else {
            console.log("Daten setzen fehlgeschlagen")
        }

    }

    @action setTracking = (data) => {
        if(data) {
            this.tracking = {
                orderStatus: data.orderStatus,
                returnStatus: data.returnStatus,
                label: data.label
            }
        } else {
            console.log("Daten setzen fehlgeschlagen")
        }
    }

    @action setToken = (data) => {
        if(data) {
            this.token = {
                accessToken: data.accessToken,
                tokenType: data.tokenType,
            }
        } else {
            console.log("Daten setzen fehlgeschlagen")
        }
    }

    
    @action setReturns(data) {

        if(data) {

            for(var i = 0; i < data.length; i++) {

                this.returns[i] = data[i]
    
            }
        } else {
            console.log("Daten setzen fehlgeschlagen")
        }

    }

    @action setArchivedReturns(data) {

        if(data) {

            for(var i = 0; i < data.length; i++) {

                this.archivedReturns[i] = data[i]
    
            }
        } else {
            console.log("Daten setzen fehlgeschlagen")
        }

    }

    @action setCurrentArticles(data) {

        if(data) {

            for(var i = 0; i < data.length; i++) {

                this.currentArticles[i] = {
                    id: data[i].id,
                    articleId: data[i].articleId,
                    articleName: data[i].articleName,
                    price: data[i].price,
                    quantity: data[i].quantity,
                    returnReason: data[i].returnReason,
                    numberOfReturns: data[i].numberOfReturns,
                    articleStatusId: data[i].articleStatusId
                }
    
            }
        } else {
            console.log("Daten setzen fehlgeschlagen")
        }

    }

    @action setCurrentReturn(data) {
        if(data) {
            this.currentReturn = data
        }
    }

    @action setIsUp(data) {
        this.isUp = data
    }

    /**load() {
        if(sessionStorage.getItem("store")) {
            const data = sessionStorage.getItem("store")
            this.setOrderResponse(JSON.parse(data).orderResponse)
        }
    }

    save(json) {
        sessionStorage.setItem("store", json)
    
    }*/

    /**
     * Snackbar
     */
    @observable notifications = [];

    @observable @action
    enqueueSnackbar = (note) => {
        this.notifications.push({
            key: new Date().getTime() + Math.random(),
            ...note,
        });
    }

    @observable @action
    removeSnackbar = (note) => {
        this.notifications.remove(note);
    }

    /**
    @action reset() {
        sessionStorage.removeItem("store")

        extendObservable(this, {
            orderIdInput: {value:undefined},
            postcodeInput: {value:undefined},
            returnTypeInput: {value:"NOCH_NICHTS"},
            orderRequest: {},
            orderResponse: {},
            articles: [],
            labels: [],
            orderIdInputTracking: {value:undefined},
            postcodeInputTracking: {value:undefined},
            tracking: {value:undefined}
        });
      } */

}

export const appStore = new AppStore()
