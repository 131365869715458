import React, { Component } from 'react'

import { inject, observer } from 'mobx-react'
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import history from '../../history';
import { isBackendAuthenticated, getAllReturns, getAllArchivedReturns } from '../../utils/APIUtils';
import { AppBar, Grid, IconButton, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography } from '@material-ui/core';
import { BrowserRouter, Link, Route, Switch } from 'react-router-dom';
import Submitted from './Submitted';
import Archived from './Archived';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CachedOutlined } from '@material-ui/icons';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        className="w-100"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
            <Typography>{children}</Typography>
        )}
      </div>
    );
  }

  
  
@inject("appStore")
@observer
export default class Login extends Component {
    constructor(props) {
        super(props)
        

        this.state = {
            isLoading: false,
            isReloading: false,
            activeTab: 0,
        }

        

    }

    componentDidMount() {
        var store = this.props.appStore
        if(!store.token.accessToken) {
            store.enqueueSnackbar({
                message: "Sie müssen eingeloggt sein, um auf diese Seite zuzugreifen!",
                options: {
                    variant: 'error',
                },
            });
            history.push("/backend/login")
        } else {
            //check authentication
            isBackendAuthenticated(store.token.accessToken, store.lang).then(
                (response) => {
                    //catch all returns
                    getAllReturns(store.token.accessToken, store.lang).then(
                        (response) => {
                            console.log(response)
                            store.resetReturns()
                            store.setReturns(response.data)
                            this.setState({isLoading:true})
                            //history.push("/backend/submitted")
                        },
                        (error) => {
                            store.enqueueSnackbar({
                                message: error.response.data.message,
                                options: {
                                    variant: 'error',
                                },
                            });
                        }
                    ).catch(
                        (error) => {
                            store.enqueueSnackbar({
                                message: "Unbekannter Fehler aufgetreten. Bitte melden Sie sich beim Kundenservice",
                                options: {
                                    variant: 'error',
                                },
                            });
                            console.log(error)
                        }
                    )

                    getAllArchivedReturns(store.token.accessToken, store.lang).then(
                        (response) => {
                            store.resetArchivedReturns()
                            store.setArchivedReturns(response.data)
                            this.setState({isLoading:true})
                            //history.push("/backend/submitted")
                        },
                        (error) => {
                            store.enqueueSnackbar({
                                message: error.response.data.message,
                                options: {
                                    variant: 'error',
                                },
                            });
                        }
                    ).catch(
                        (error) => {
                            store.enqueueSnackbar({
                                message: "Unbekannter Fehler aufgetreten. Bitte melden Sie sich beim Kundenservice",
                                options: {
                                    variant: 'error',
                                },
                            });
                            console.log(error)
                        }
                    )

                },
                (error) => {
                    store.enqueueSnackbar({
                        message: "Sie müssen eingeloggt sein, um auf diese Seite zuzugreifen!",
                        options: {
                            variant: 'error',
                        },
                    });
                    store.token = {}
                    history.push("/backend/login")
                }
            ).catch(
                (error) => {
                    store.enqueueSnackbar({
                        message: "Unbekannter Fehler aufgetreten. Bitte melden Sie sich beim Kundenservice",
                        options: {
                            variant: 'error',
                        },
                    });
                    console.log(error)
                    store.token = {}
                    history.push("/backend/login")
                }
            )
        }
    }

    handleLogout = (e) => {
        e.preventDefault()
        var store = this.props.appStore
        store.token = {}
        history.push("/backend/login")
    }

    handleChange = (event, activeTab) => {
        this.setState({ activeTab });
      };
    
    handleChangeIndex = index => {
        this.setState({ activeTab: index });
    };
    
    handleReload = (e) => {
        e.preventDefault()
        this.setState({isReloading:true});
        var store = this.props.appStore

        setTimeout(function(){
            getAllArchivedReturns(store.token.accessToken, store.lang).then(
                (response) => {
                    store.resetArchivedReturns()
                    store.setArchivedReturns(response.data)
                    this.setState({isLoading:true})
                    //history.push("/backend/submitted")
                },
                (error) => {
                    store.enqueueSnackbar({
                        message: error.response.data.message,
                        options: {
                            variant: 'error',
                        },
                    });
                }
            ).catch(
                (error) => {
                    store.enqueueSnackbar({
                        message: "Unbekannter Fehler aufgetreten. Bitte melden Sie sich beim Kundenservice",
                        options: {
                            variant: 'error',
                        },
                    });
                    console.log(error)
                }
            )

            getAllReturns(store.token.accessToken, store.lang).then(
                (response) => {
                    store.resetReturns()
                    store.setReturns(response.data)
                    this.setState({isReloading:false});
                },
                (error) => {
                    store.enqueueSnackbar({
                        message: error.response.data.message,
                        options: {
                            variant: 'error',
                        },
                    });
                }
            ).catch(
                (error) => {
                    store.enqueueSnackbar({
                        message: "Unbekannter Fehler aufgetreten. Bitte melden Sie sich beim Kundenservice",
                        options: {
                            variant: 'error',
                        },
                    });
                    console.log(error)
                }
            )
        }.bind(this), 1000);

        

        
    }

    render() {
        var store = this.props.appStore

        var reloading;
        if (this.state.isReloading) {
            reloading = <CircularProgress style={{ fontSize: 16 }}/>
        } else {
            reloading = <IconButton  onClick={this.handleReload} variant="contained" color="primary" aria-label="reload"><CachedOutlined /></IconButton>
        }

        if(this.state.isLoading) {
            return (
                <div className="container">
                    
                        <Container component="main" maxWidth="xl">
                            <Grid 
                                container
                                direction="column"
                                justify="center"
                                alignItems="center"
                            >
                                <Grid container
                                    className="mb-16"
                                    direction="row"
                                    justify="space-between"
                                    alignItems="stretch"
                                >
                                    {reloading}
                                    <Button variant="contained" color="primary" onClick={this.handleLogout}>Ausloggen</Button>
                                </Grid>

                                {/**<BrowserRouter history={history}>
                                <div> to="/backend/archived" */}
                                <AppBar position="static" color="default">
                                    <Tabs
                                    value={this.state.activeTab}
                                    onChange={this.handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="fullWidth"
                                    >
                                    <Tab label="Laufende Retouren"/>
                                    <Tab label="Abgeschlossene Retouren"/>
                                    </Tabs>
                                </AppBar>
                                <TabPanel value={this.state.activeTab} index={0}>
                                    <Submitted/>
                                </TabPanel>
                                <TabPanel value={this.state.activeTab} index={1}>
                                    <Archived/>
                                </TabPanel>
                            {/**
                                <Switch>
                                    <Route path="/backend/submitted" component={Submitted} />
                                    <Route path="/backend/archived" component={Archived} />
                                </Switch>
                                </div>
                            </BrowserRouter>
                             */}
                            
                        </Grid>
                        </Container>
                </div>
            )
        } else {
            return (
                <div className="container">
                    <Container>
                        <Grid 
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                        > 
                            <CircularProgress/>
                        </Grid>
                    </Container>
                </div>
            )
        }
        
    }
}
