// src/ui/theme/index.js

//https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=263238&secondary.color=455A64

import { createMuiTheme } from '@material-ui/core/styles';

const palette = {
  primary: { main: '#263238' },
  secondary: { main: '#455A64' }
};
const themeName = 'Outer Space Fiord Gayal';


export default createMuiTheme({ palette, themeName});
