import React, { Component } from 'react'
import { Select, MenuItem, Container, Typography, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Slide, CircularProgress, Grid, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { spacing } from '@material-ui/system';
import { Link } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { getArticlesByOrder, setNumberAndReason, abortReturn } from '../../utils/APIUtils'
import history from '../../history';
import { withSnackbar } from 'notistack';
import {getSafe} from '../../utils/Utils';
import {isAuthenticated} from '../../utils/Utils';
import { withTranslation } from 'react-i18next';
 
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

@inject("appStore")
@observer
class Article extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            isOpen: false,
            submitDisabled: false
        }

        const store = this.props.appStore
        const {t} = this.props
        var orderResponse = store.orderResponse

        if(!isAuthenticated(orderResponse)) {
            history.push("/")
            store.enqueueSnackbar({
                message: t('error.no-access'),
                options: {
                    variant: 'error',
                },
            });
        } else {
            this.getArticles()
        }

    }

    componentDidMount() {

    }

    getArticles = () => {
        const store = this.props.appStore
        const {t} = this.props
        var internalId = getSafe(() => store.orderResponse.id, -1)
        var postcode = getSafe(() => store.orderResponse.postcode, -1)

        console.log("id: "+internalId)
        console.log("plz: "+postcode)
        getArticlesByOrder({internalOrderId: internalId, postcode: postcode}, this.lang).then(
            result => {
                var data = result.data
                store.resetArticles()
                store.setArticles(data)
                this.setState({isLoading:false})
            },
            error => {
                if(error.response.data.returnToHome) {
                    history.push("/") 
                }
                store.enqueueSnackbar({
                    message: error.response.data.message,
                    options: {
                        variant: 'error',
                    },
                    
                });
            
            }
        ).catch(
            error => {
                store.enqueueSnackbar({
                    message: t('error.unknown'),
                    options: {
                        variant: 'error',
                    },
                });
                console.log(error)
            }
        )
    }

    handleNumberChange = (e, index) => {
        const store = this.props.appStore
        var value = e.target.value
        store.setArticlesNumberOfReturns(value, index)
    }


    handleReasonChange = (e, index) => {
        const store = this.props.appStore
        var value = e.target.value
        store.setArticlesReason(value, index)
    }

    handleButtonOverview = (e) => {
        if(this.state.submitDisabled) {
            return
        }

        this.setState({submitDisabled:true})

        const store = this.props.appStore
        const {t} = this.props

        var postcode = store.orderResponse.postcode
        var internalOrderId = store.orderResponse.id
        var request = []
        var sumNumberOfReturns = 0;
        store.articles.map((article) => {
            var item = {
                internalArticleId: article.id,
                internalOrderId: internalOrderId,
                numberOfReturns: article.numberOfReturns,
                returnReason: article.returnReason,
                postcode: postcode
            }
            sumNumberOfReturns += article.numberOfReturns;
            request.push(item)
        })

        if(sumNumberOfReturns <= 0) {
            store.enqueueSnackbar({
                message:  t('validation.number-of-returns'),
                options: {
                    variant: 'error',
                },
            });
            this.setState({submitDisabled:false})
        } else {
            setNumberAndReason(request, store.lang).then(
                result => {
                    history.push("/overview")
                    store.enqueueSnackbar({
                        message: t('info.overview'),
                        options: {
                            variant: 'info',
                        },
                    });
                    this.setState({submitDisabled:false})
                },
                error => {
                    if(error.response.data.returnToHome) {
                        history.push("/") 
                    }
                    store.enqueueSnackbar({
                        message: error.response.data.message,
                        options: {
                            variant: 'error',
                        },
                    });
                    this.setState({submitDisabled:false})
                }
            ).catch(
                error => {
                    store.enqueueSnackbar({
                        message: t('error.unknown'),
                        options: {
                            variant: 'error',
                        },
                    });
                    console.log(error)
                    this.setState({submitDisabled:false})
                }
            )
        }
        
    }

    abort = () => {
        const store = this.props.appStore
        var request = { internalOrderId : store.orderResponse.id, postcode: store.orderResponse.postcode }

        abortReturn(request, store.lang).then(
            result => {
                console.log(result.data)
                history.push("/")
            },
            error => {
                if(error.response.data.returnToHome) {
                    history.push("/") 
                }
                store.enqueueSnackbar({
                    message: error.response.data.message,
                    options: {
                        variant: 'error',
                    },
                });
            }
        ).catch(
            error => {
                store.enqueueSnackbar({
                    message: t('error.unknown'),
                    options: {
                        variant: 'error',
                    },
                });
                console.log(error)
            }
        )
    }

    handleClickOpen = () => {
        this.setState({isOpen: true});
    };
    
    handleCloseYes = () => {
        this.setState({isOpen: false});
        this.abort()
    };

    handleCloseNo= () => {
        this.setState({isOpen: false});
    };

    render() {
        const store = this.props.appStore
        const {t} = this.props
        var isLoading = this.state.isLoading

        var buttonCondition;
        if(this.state.submitDisabled) {
            buttonCondition = <CircularProgress/>
        } else {
            buttonCondition = t('article.button-overview')
        }

        if(isLoading) {
            return (
                <div className="container">
                    <Container>
                        <Grid 
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                        > 
                            <CircularProgress/>
                        </Grid>
                    </Container>
                </div>
            )
        } else {
            return (
                <div className="container">
                    <Container>
                        <div className="mb-16">
                            <Typography component="h1" variant="h6">
                                {t('article.text')}
                            </Typography>
                        </div>
                        <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                            <TableRow>
                                <TableCell>{t('article.table-number-of-returns')}</TableCell>
                                <TableCell align="right">{t('article.table-article')}</TableCell>
                                <TableCell align="right">{t('article.table-price')}</TableCell>
                                <TableCell align="right">{t('article.table-reason')}</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {store.articles.map((row, index) => (
                                <TableRow key={row.id}>
                                <TableCell align="right">
                                    <Select 
                                        className="blue-select"
                                        labelId="number-of-return" 
                                        id="number-of-return" 
                                        value={row.numberOfReturns}
                                        onChange={(e) => this.handleNumberChange(e, index)}>
                                        {Array.from(Array(row.quantity+1).keys()).map((row, index) => (
                                            <MenuItem key={index} value={index}>{index}</MenuItem>
                                        ))}
                                    </Select>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.articleName}
                                </TableCell>
                                <TableCell align="right">{row.price}</TableCell>
                                <TableCell align="right">
                                    <Select 
                                        className="blue-select"
                                        labelId="number-of-return" 
                                        id="number-of-return" 
                                        value={row.returnReason}
                                        onChange={(e) => this.handleReasonChange(e, index)}
                                        disabled={row.numberOfReturns === 0}
                                    >
                                        <MenuItem value={"NOCH_NICHTS"}>{t('article.select-nothing')}</MenuItem>
                                        <MenuItem value={"BRUCH"}>{t('article.select-broken')}</MenuItem>
                                        <MenuItem value={"KRATZER"}>{t('article.select-scratch')}</MenuItem>
                                        <MenuItem value={"SCHLECHTE_QUALITAET"}>{t('article.select-quality')}</MenuItem>
                                        <MenuItem value={"FALSCHE_LIEFERUNG"}>{t('article.select-delivery')}</MenuItem>
                                        <MenuItem value={"SONSTIGES"}>{t('article.select-na')}</MenuItem>
                                    </Select>
                                </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                        </TableContainer>

                        <Box className="mt-16" display="flex" justifyContent="space-between" flexDirection="row-reverse">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleButtonOverview}
                                    disabled={this.state.submitDisabled}
                                >
                                    {buttonCondition}
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    onClick={this.handleClickOpen}
                                    disabled={this.state.submitDisabled}
                                >
                                    {t('article.button-cancel')}
                                </Button>
                        </Box>

                        
                                
                                   
                    </Container>
                    <Dialog
                        open={this.state.isOpen}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={this.handleClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title">{t('article.cancel-dialog-heading')}</DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            {t('article.cancel-dialog-text')}
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={this.handleCloseYes} color="primary">
                            {t('yes')}
                        </Button>
                        <Button onClick={this.handleCloseNo} color="primary">
                            {t('no')}
                        </Button>
                        </DialogActions>
                    </Dialog>

                </div>
            );
        }
        
    }
}
export default withSnackbar(withTranslation('common')(Article))