import React from 'react';
import {
  Route,
  withRouter,
  Switch
} from 'react-router-dom';

import Login from '../components/admin/Login'
import Return from '../components/return/Return'
import NotFound from '../common/NotFound'
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import Article from '../components/return/Article';
import Overview from '../components/return/Overview';
import Label from '../components/return/Label';
import Backend from '../components/admin/Backend';
import Submitted from '../components/admin/Submitted';
import Archived from '../components/admin/Archived';

import { withSnackbar } from 'notistack'
import Notifier from '../Notifier';
import { Button, CircularProgress, Container, Grid, Typography } from '@material-ui/core'
import { inject, observer } from 'mobx-react';
import TrackingForm from '../components/tracking/TrackingForm';
import TrackingStatus from '../components/tracking/TrackingStatus';
import { useRef, useEffect, useState } from 'react';
import Activate from '../components/Email/Activate';
import Deactivate from '../components/Email/Deactivate';
import { isUp as isUpAPI } from '../utils/APIUtils';
import { useTranslation } from 'react-i18next';


//<PrivateRoute authenticated={this.state.isAuthenticated} path="/poll/new" component={NewPoll} handleLogout={this.handleLogout}></PrivateRoute>
var App = inject("appStore")(observer((props) => {

  var [isUp, setIsUp] = useState(true);
  const [t, i18n] = useTranslation('common');

  useEffect(() => {
    var store = props.appStore;

    if(store.lang == 'de') {
      i18n.changeLanguage('de')
    } else if(store.lang == 'en') {
      i18n.changeLanguage('en')
    } 

    const interval = setInterval(function() {
      isUpAPI(store.lang).then((response) => {
        console.log(response)
        if(response.status === 200) {
            setIsUp(true)
        } else {
            setIsUp(false)
        }
        
      }, 
      (error) => {
          setIsUp(false)
      })
    }.bind(this)
      , 5000, store.lang);
  }, []);

  if(isUp === true) {
    return (
      <div className="app-container">
        <Notifier/>
        <Header />           
          <Switch>      
              <Route exact path="/" 
                  render={(props) => <Return />}>
              </Route>
              <Route exact path="/article" 
                  render={(props) => <Article />}>
              </Route>
              <Route exact path="/overview" 
                  render={(props) => <Overview />}>
              </Route>
              <Route exact path="/label" 
                  render={(props) => <Label />}>
              </Route>
              <Route exact path="/tracking" 
                  render={(props) => <TrackingForm />}>
              </Route>
              <Route exact path="/tracking/status" 
                  render={(props) => <TrackingStatus />}>
              </Route>
              <Route path="/backend/login" 
                    render={(props) => <Login />}></Route>
              <Route path="/backend" 
                    render={(props) => <Backend />}></Route>
              <Route path="/email/activate" 
                    render={(props) => <Activate />}></Route>
              <Route path="/email/deactivate" 
                    render={(props) => <Deactivate />}></Route>
              <Route component={NotFound}></Route>
            </Switch>
        <Footer />
      </div>
    );
  } else {
    return (
      <div className="app-container">
        <Notifier/>
        <Header />           
          <div className="container">
                    <Container>
                        <Grid 
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                        > 
                            <CircularProgress/>
                            <Typography className="mt-32" component="h1" variant="subtitle1">
                              {t('error.no-connection')}
                            </Typography>
                        </Grid>
                    </Container>
                </div>
        <Footer />
      </div>
    )
  }

  
  
  }))

export default withSnackbar(App);
