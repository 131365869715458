import React, { Component } from 'react'
import { Container, Typography, Button, Grid } from '@material-ui/core'
import { getLastLabel } from '../../utils/APIUtils'
import { observer, inject } from 'mobx-react'
import { withSnackbar } from 'notistack'
import history from '../../history'
import { getSafe, isAuthenticated } from '../../utils/Utils'
import CircularProgress from '@material-ui/core/CircularProgress';
import { withTranslation } from 'react-i18next'

@inject("appStore")
@observer
class Label extends Component {
    constructor(props) {
        super(props)

        this.state = {
           isLoading: true
        };

        const store = this.props.appStore
        const {t} = this.props

        if(!isAuthenticated(store.orderResponse)) {
            history.push("/")
            store.enqueueSnackbar({
                message: t('error.no-access'),
                options: {
                    variant: 'error',
                },
            });
        } else {
            const internalOrderId = getSafe(() => store.orderResponse.id, -1)
            const postcode = getSafe(() => store.orderResponse.postcode, -1)
            const request = {internalOrderId: internalOrderId, postcode: postcode}
            var value = getLastLabel(request, store.lang).then(
                (result) => {
                    store.setLabel(result.data)
                    console.log(store.label)
                    this.setState({isLoading:false})
                },
                error => {
                    if(error.response.data.returnToHome) {
                        history.push("/") 
                    }
                    store.enqueueSnackbar({
                        message: error.response.data.message,
                        options: {
                            variant: 'error',
                        },
                    });
                }
            ).catch(
                error => {
                    store.enqueueSnackbar({
                        message: t('error.unknown'),
                        options: {
                            variant: 'error',
                        },
                    });
                    console.log(error)
                }
            )
        }

        
    }

    componentDidMount() {
        
    }

    render() {
        const store = this.props.appStore
        const {t} = this.props
        var isLoading = this.state.isLoading

        if(isLoading) {
            return (
                <div className="container">
                    <Container>
                        <Grid 
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                        > 
                            <CircularProgress/>
                        </Grid>
                    </Container>
                </div>
            )
        } else {
            return (
                <div className="container">
                    <Container>
                        <Typography component="h1" variant="h5">
                            {t('label.heading')}
                        </Typography>
                            <p>{t('label.text-1')}<br/>
                            {t('label.text-2')}</p>
                        <a className="link" href={"data:application/pdf;base64,"+store.label.labelData} download="return.pdf">
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                            >
                                {t('label.button-download')}
                            </Button>
                        </a>
                    </Container>
                </div>
                
            )
        }
    
    }
} export default withSnackbar(withTranslation('common')(Label))
