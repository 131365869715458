
import { API_BASE_URL, ACCESS_TOKEN } from '../constants'
import axios from 'axios';

export async function isUp(language) {
    var config = { 
        headers: {
            'Accept-Language': language
        }
     }
    return await axios.get(API_BASE_URL + "/auth/up", config)
        .then((res) => {
            return res
        }).catch(
            (error) => {
                throw error
            }
        )
}

export async function signin(request, language) {
    var config = { 
        headers: {
            'Accept-Language': language
        }
     }
    return await axios.post(API_BASE_URL + "/auth/signin", request, config)
        .then(res => {
            return res
        }).catch(
            (error) => {
                throw error
            }
        )
}

export async function startReturn(request, language) {
    var config = { 
        headers: {
            'Accept-Language': language
        }
     }

    return await axios.post(API_BASE_URL + "/return/start", request, config)
        .then(res => {
            return res
        }).catch(
            (error) => {
                throw error
            }
        )
}

export async function getArticlesByOrder(request, language) {
    var config = { 
        headers: {
            'Accept-Language': language
        }
     }
    return await axios.post(API_BASE_URL + "/return/articles/", request, config).then(
        (res) => {
            return res
        }
    ).catch(
        (error) => {
            throw error
        }
    )
}

export async function getArticlesOverviewByOrder(request, language) {
    var config = { 
        headers: {
            'Accept-Language': language
        }
     }

    return await axios.post(API_BASE_URL + "/return/articles/overview", request, config).then(
        (res) => {
            return res
        }
    ).catch(
        (error) => {
            throw error
        }
    )
}

export async function setNumberAndReason(request, language) {
    var config = { 
        headers: {
            'Accept-Language': language
        }
     }

    return await axios.post(API_BASE_URL + "/return/reason", request, config).then(
        (res) => {
            return res
        }
    ).catch(
        (error) => {
            throw error
        }
    )
}

export async function abortReturn(request, language) {
    var config = { 
        headers: {
            'Accept-Language': language
        }
     }

    return await axios.post(API_BASE_URL + "/return/abort", request, config).then(
        (res) => {
            return res
        }
    ).catch(
        (error) => {
            throw error
        }
    )
}

export async function createLabel(request, language) {
    var config = { 
        headers: {
            'Accept-Language': language
        }
     }

    return await axios.post(API_BASE_URL + "/return/label", request, config).then(
        (res) => {
            return res
        }
    ).catch(
        (error) => {
            throw error
        }
    )
}

export async function getLastLabel(request, language) {
    var config = { 
        headers: {
            'Accept-Language': language
        }
     }

    return await axios.post(API_BASE_URL + "/return/label/last", request, config).then(
        (res) => {
            return res
        }
    ).catch(
        (error) => {
            throw error
        }
    )
}

export async function getTracking(request, language) {
    var config = { 
        headers: {
            'Accept-Language': language
        }
     }

    return await axios.post(API_BASE_URL + "/return/tracking", request, config).then(
        (res) => {
            return res
        }
    ).catch(
        (error) => {
            throw error
        }
    )
}

/**
 * API Calls mit Authentication
 */

export async function isBackendAuthenticated(token, language) {
    var config = { 
        headers: {
            'Accept-Language': language,
            'Authorization': `Bearer ${token}`
        }
     }

    return await axios.get(API_BASE_URL + "/backend/authenticated", config).then(
        (res) => {
            return res
        }
    ).catch(
        (error) => {
            throw error
        }
    )
}

export async function getAllReturns(token, language) {
    var config = { 
        headers: {
            'Accept-Language': language,
            'Authorization': `Bearer ${token}`
        }
     }

    return await axios.get(API_BASE_URL + "/backend/returns", config).then(
        (res) => {
            return res
        }
    ).catch(
        (error) => {
            throw error
        }
    )
}

export async function getAllArticlesByOrderId(orderId, token, language) {
    var config = { 
        headers: {
            'Accept-Language': language,
            'Authorization': `Bearer ${token}`
        }
     }

    return await axios.get(API_BASE_URL + "/backend/articles/"+orderId, config).then(
        (res) => {
            return res
        }
    ).catch(
        (error) => {
            throw error
        }
    )
}

export async function getAllArchivedReturns(token, language) {
    var config = { 
        headers: {
            'Accept-Language': language,
            'Authorization': `Bearer ${token}`
        }
     }
    return await axios.get(API_BASE_URL + "/backend/archived-returns", config).then(
        (res) => {
            return res
        }
    ).catch(
        (error) => {
            throw error
        }
    )
}

export async function getAllArchivedArticlesById(id, token, language) {
    var config = { 
        headers: {
            'Accept-Language': language,
            'Authorization': `Bearer ${token}`
        }
     }

    return await axios.get(API_BASE_URL + "/backend/archived-articles/"+id, config).then(
        (res) => {
            return res
        }
    ).catch(
        (error) => {
            throw error
        }
    )
}


export async function setReturnStatusFinished(orderId, token, language) {
    var config = { 
        headers: {
            'Accept-Language': language,
            'Authorization': `Bearer ${token}`
        }
     }

    return await axios.get(API_BASE_URL + "/backend/return/status/"+orderId, config).then(
        (res) => {
            return res
        }
    ).catch(
        (error) => {
            throw error
        }
    )
}

export async function deleteReturn(orderId, token, language) {
    var config = { 
        headers: {
            'Accept-Language': language,
            'Authorization': `Bearer ${token}`
        }
     }

    return await axios.delete(API_BASE_URL + "/backend/delete/"+orderId, config).then(
        (res) => {
            return res
        }
    ).catch(
        (error) => {
            throw error
        }
    )
}

/** Email Notification */

export async function activateEmail(emailToken, language) {
    var config = { 
        headers: {
            'Accept-Language': language,
        }
     }

    return await axios.post(API_BASE_URL + "/email/opt-in/", {token:emailToken, activate: true}, config).then(
        (res) => {
            return res
        }
    ).catch(
        (error) => {
            throw error
        }
    )
}

export async function deactivateEmail(emailToken, language) {
    var config = { 
        headers: {
            'Accept-Language': language,
        }
     }

    return await axios.post(API_BASE_URL + "/email/opt-in/", {token:emailToken, activate: false}, config).then(
        (res) => {
            return res
        }
    ).catch(
        (error) => {
            throw error
        }
    )
}