import React, { Component } from 'react'

import { inject, observer } from 'mobx-react'
import Button from '@material-ui/core/Button';
import { Container, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import history from '../../history';
import Qs from "qs";
import { activateEmail, deactivateEmail } from '../../utils/APIUtils';
import { withTranslation } from 'react-i18next';
  
@inject("appStore")
@observer
class Deactivate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true
        }
    }

    componentDidMount() {
        var store = this.props.appStore


        if(history.location) {
            var search = Qs.parse(history.location.search, { ignoreQueryPrefix: true }).activation_key
            if(search) {
                store.activationKey = search
                console.log(store.activationKey)
            } else {
                console.log("kein token gefunden")
            }
        }
        this.setState({isLoading:false})
    }

    handleClick = () => {
        var store = this.props.appStore
        const {t} = this.props
        deactivateEmail(store.activationKey, store.lang).then(
            (response) => {
                store.enqueueSnackbar({
                    message: t('success.email-deactivated'),
                    options: {
                        variant: 'success',
                    },
                });
                history.push("/")
            },
            (error) => {
                store.enqueueSnackbar({
                    message: error.response.data.message,
                    options: {
                        variant: 'error',
                    },
                });
            }
        ).catch(
            (error) => {
                store.enqueueSnackbar({
                    message: t('error.unknown'),
                    options: {
                        variant: 'error',
                    },
                });
                console.log(error)
            }
        )

    }

    render() {
        var store = this.props.appStore
        const {t} = this.props

        if(this.state.isLoading) {
            return (
                <div>Loading</div>
            )
       
        } else {
            return (
                <div className="container">
                <Container>
                    <Grid 
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                    >
                    <Typography component="h1" variant="h4">
                        {t('email.deactivate-heading')}
                    </Typography>
                    <Typography className="mb-16" component="p" variant="subtitle1">
                        {t('email.deactivate-text')}
                    </Typography>
                    <Button variant="contained" color="primary" onClick={this.handleClick}>{t('email.deactivate-button')}</Button>
                    </Grid>
                </Container>
            </div>
            )
        }
                
            
    }
} export default withTranslation('common')(Deactivate)

