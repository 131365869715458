import React, { Component } from 'react'
import { Container, Grid, Typography, TextField, Button, Select, MenuItem, FormControlLabel, Checkbox, Box } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { startReturn } from '../../utils/APIUtils'
import { inject, observer } from 'mobx-react'
import history from '../../history';
import { withSnackbar } from 'notistack'
import CircularProgress from '@material-ui/core/CircularProgress';
import { CheckBox } from '@material-ui/icons'
import {useTranslation, withTranslation} from "react-i18next";

@inject("appStore")
@observer
class Return extends Component {
    constructor(props) {
        super(props)

        this.state = {
            submitDisabled: false,
            dataChecked: false
        }

    }

    componentDidMount() {
        var store = this.props.appStore;
        store.resetInputs()
        store.resetArticles()
        store.resetOrder()
    
    }

    getOrder = () => {
        const store = this.props.appStore
        const {t} = this.props
        var orderRequest = {orderId: store.orderIdInput, postcode: store.postcodeInput, email: store.emailInput, returnType: "RUECKGABE"}
        var numberOfErrors = 0
        
        /**if(store.returnTypeInput === "NOCH_NICHTS") {
            numberOfErrors++
            store.enqueueSnackbar({
                message: t('validation.reason'),
                options: {
                    variant: 'error',
                },
            });
            this.setState({submitDisabled:false})
        } **/

        if(store.orderIdInput === "" || store.orderIdInput === undefined || store.postcodeInput === "" || store.postcodeInput === undefined) {
            numberOfErrors++
            store.enqueueSnackbar({
                message: t('validation.fields'),
                options: {
                    variant: 'error',
                },
            });
            this.setState({submitDisabled:false})
        }

        if(!this.state.dataChecked) {
            numberOfErrors++
            store.enqueueSnackbar({
                message: t('validation.data-privacy'),
                options: {
                    variant: 'error',
                },
            });
            this.setState({submitDisabled:false})
        }
        
        var noErrors = (numberOfErrors === 0)
        if (noErrors && (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(store.emailInput) || store.emailInput === "" || store.emailInput === undefined
                || store.emailInput === null) ) {
                startReturn(orderRequest, store.lang).then(
                    result => {
                        var data = result.data
                        store.orderResponse = {}
                        store.setOrderResponse(data)
                        history.push("/article")
                        this.setState({submitDisabled:false})
                        
                        /**store.enqueueSnackbar({
                            message: t('info.email'),
                            options: {
                                variant: 'info',
                            },
                        });**/
                    },
                    error => {
                        store.enqueueSnackbar({
                            message: error.response.data.message,
                            options: {
                                variant: 'error',
                            },
                        });
                        this.setState({submitDisabled:false})
                    }
                ).catch(
                    error => {
                        store.enqueueSnackbar({
                            message: t('error.unknown'),
                            options: {
                                variant: 'error',
                            },
                        });
                        console.log(error)
                        this.setState({submitDisabled:false})
                    }
                )
        } else {
            if(noErrors) {
                store.enqueueSnackbar({
                    message: t('validation.email'),
                    options: {
                        variant: 'error',
                    },
                });
                this.setState({submitDisabled:false})
            }   
        }

        


    }

    handleOrderIdOnChange = (e) => {
        e.preventDefault()
        var value = e.target.value
        const store = this.props.appStore
        store.orderIdInput = value
    }

    handlePostCodeOnChange = (e) => {
        e.preventDefault()
        var value = e.target.value
        const store = this.props.appStore
        store.postcodeInput = value
    }

    handleEmailChange = (e) => {
        e.preventDefault()
        var value = e.target.value
        const store = this.props.appStore
        store.emailInput = value
    }

    handleReturnTypeOnChange = (e) => {
        var value = e.target.value
        const store = this.props.appStore
        store.returnTypeInput = value
    }

    handleButtonOnClick = (e) => {
        e.preventDefault()
        if(this.state.submitDisabled) {
            return;
        }
        this.setState({submitDisabled:true})
        this.getOrder()
    }

    handleDataCheckedChange = (e) => {
        this.setState({dataChecked: e.target.checked});
    }

    render() {
        const store = this.props.appStore
        const {t} = this.props;

        var buttonCondition;
        if(this.state.submitDisabled) {
            buttonCondition = <CircularProgress />
        } else {
            buttonCondition = t('return.start-button')
        }

        return (
            <div className="container">
                <Container>
                    <Grid 
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                    >
                        <Typography component="h1" variant="h4">
                        Edzard {t('return.portal')}
                        </Typography>
                        <Typography component="p" variant="subtitle1">
                            {t('return.info')}
                        </Typography>
                        <form noValidate>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="bestellnummer"
                                label={t('return.field-order-number')}
                                name="bestellnummer"
                                autoComplete="bestellnummer"
                                autoFocus
                                onChange={this.handleOrderIdOnChange}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="postleitzahl"
                                label={t('return.field-zip')}
                                type="postleitzahl"
                                id="postleitzahl"
                                autoComplete="postleitzahl"
                                onChange={this.handlePostCodeOnChange}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="email"
                                label={t('return.field-email')}
                                type="email"
                                id="email"
                                autoComplete="email"
                                onChange={this.handleEmailChange}
                            />
                            {/**<Select
                                    labelId="type-of-return"
                                    id="type-of-return"
                                    value={store.returnTypeInput}
                                    className="mt-16 mb-8 blue-select"
                                    onChange={this.handleReturnTypeOnChange}
                                >
                                <MenuItem value={"NOCH_NICHTS"}>{t('return.select-nothing')}</MenuItem>
                                <MenuItem value={"RUECKGABE"}>{t('return.select-return')}</MenuItem>
                                <MenuItem value={"UMTAUSCH"}>{t('return.select-exchange')}</MenuItem>
                            </Select><br/>**/}
                            <FormControlLabel
                                className="mt-16"
                                control={
                                <Checkbox
                                    checked={this.state.dataChecked}
                                    onChange={this.handleDataCheckedChange}
                                    name="dataChecked"
                                    color="primary"
                                />
                                }
                                label={t('return.checkbox-data-privacy')}
                            />
                            <Box className="mt-16" display="flex" flexDirection="row-reverse">
                                <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        onClick={this.handleButtonOnClick}
                                        disabled={this.state.submitDisabled}
                                    >
                                        {buttonCondition}
                                    </Button>
                            </Box>

                                    
                            </form>
                    </Grid>
                </Container>
            </div>
        )
    }
}
export default withSnackbar(withTranslation('common')(Return))
