import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/App';
import './global.css';
import * as serviceWorker from './serviceWorker';
import { Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import theme from './ui/theme';
import { Provider } from 'mobx-react';
import { appStore } from './stores/AppStore';
import history from './history'
import { SnackbarProvider } from 'notistack';
import { create } from 'mobx-persist';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import common_de from "./translations/de/common.json";
import common_en from "./translations/en/common.json";



const hydrate = create({
  storage: localStorage,   // or AsyncStorage in react-native.
                          // default: localStorage
  jsonify: true  // if you use AsyncStorage, here shoud be true
                  // default: true
})

hydrate("app", appStore).then(() => {
  i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: 'de',                              // language to use
    resources: {
        de: {
            common: common_de
        },
        en: {
            common: common_en               // 'common' is our custom namespace
        },
    },
  });
  ReactDOM.render(
    <Router history={history}>
      <ThemeProvider theme={theme}>
        <Provider appStore={appStore}>
            <I18nextProvider i18n={i18next}>
              <SnackbarProvider preventDuplicate>
                  <App />
              </SnackbarProvider>
            </I18nextProvider>
        </Provider>
      </ThemeProvider>
    </Router>
  ,
  document.getElementById('root')
);
})



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
