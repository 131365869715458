export function isAuthenticated(orderResponse) {
    var id = getSafe(() => orderResponse.id, false)
    var postcode = getSafe(() => orderResponse.postcode, false)

    if(!id || !postcode) {
        return false;
    } else {
        return true;
    }
}

export function getSafe(f, defaultValue) {
    try {
        return f();
    } catch(e) {
        return defaultValue;
    }
}

export function isValidReturn(articles) {
    console.log(articles.length)
    if(articles === undefined || articles.length === 0) {
        console.log("jetzt hier")
        return false
    }

    /**var output = true
    articles.forEach(element => {
        console.log(element.numberOfReturns + " und " + element.returnReason)
        if(element.numberOfReturns <= 0 && element.returnReason === "NOCH_NICHTS") {
            output = false
        }
    });**/

    return true;
}