import React, { Component } from 'react'
import { Container, Grid, Typography, TextField, Button, Select, MenuItem } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { startReturn, getTracking } from '../../utils/APIUtils'
import { inject, observer } from 'mobx-react'
import history from '../../history';
import { withSnackbar } from 'notistack'
import { getSafe } from '../../utils/Utils'
import { withTranslation } from 'react-i18next'

@inject("appStore")
@observer
class TrackingForm extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
    }

    getTracking = () => {
        const store = this.props.appStore
        const {t} = this.props
        var orderId = getSafe(() => store.trackingOrderId, -1)
        var postcode = getSafe(() => store.trackingPostcode, -1)

        if(orderId === "" || postcode === "" || orderId === undefined || postcode === undefined
            || orderId === null || postcode === null) {
            store.enqueueSnackbar({
                message: t('validations-fields'),
                options: {
                    variant: 'error',
                },
            });
  
        } else {
            history.push("/tracking/status")
        }


        
    }

    handleOrderIdOnChange = (e) => {
        e.preventDefault()
        var value = e.target.value
        const store = this.props.appStore
        store.trackingOrderId = value
    }

    handlePostcodeOnChange = (e) => {
        e.preventDefault()
        var value = e.target.value
        const store = this.props.appStore
        store.trackingPostcode = value
    }

    handleButtonOnClick = (e) => {
        e.preventDefault()
        this.getTracking();
    }


    render() {
        const store = this.props.appStore
        const {t} = this.props

        return (
            <div className="container">
                <Container>
                    <Grid 
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                    >
                        <Typography component="h1" variant="h4">
                            {t('tracking.heading')}
                        </Typography>
                        <Typography component="p" variant="subtitle1">
                            {t('tracking.text')}
                        </Typography>
                        <form noValidate>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="bestellnummer"
                                label={t('return.field-order-number')}
                                name="bestellnummer"
                                autoComplete="bestellnummer"
                                autoFocus
                                onChange={this.handleOrderIdOnChange}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="postleitzahl"
                                label={t('return.field-zip')}
                                type="postleitzahl"
                                id="postleitzahl"
                                autoComplete="postleitzahl"
                                onChange={this.handlePostcodeOnChange}
                            />
                            <div className="mt-16">
                                <Link className="link" to="/article">
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        onClick={this.handleButtonOnClick}
                                    >
                                        {t('tracking.button-track')}
                                    </Button>
                                </Link>
                            </div>
                            </form>
                    </Grid>
                </Container>
            </div>
        )
    }
}
export default withSnackbar(withTranslation('common')(TrackingForm))
