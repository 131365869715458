import React, { Component } from 'react'
import { Container, Grid, Typography, TextField, Button, Select, MenuItem, Box } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { startReturn, getTracking } from '../../utils/APIUtils'
import { inject, observer } from 'mobx-react'
import history from '../../history';
import { withSnackbar } from 'notistack'
import { getSafe } from '../../utils/Utils'
import { CloudUploadRounded, FlightTakeoff, FlightLand, MonetizationOn } from '@material-ui/icons'
import { withTranslation } from 'react-i18next'

@inject("appStore")
@observer
class TrackingStatus extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true
        }

        this.getTracking()
    }

    componentDidMount() {
    }

    getTracking = () => {
        const store = this.props.appStore
        const {t} = this.props
        var orderId = getSafe(() => store.trackingOrderId, -1)
        var postcode = getSafe(() => store.trackingPostcode, -1)

        var request = {orderId: orderId, postcode: postcode}

        getTracking(request, store.lang).then(
            (response) => {
                store.setTracking(response.data)
                this.setState({isLoading: false})
            }, 
            (error) => {
                if(error.response.data.returnToHome) {
                    history.push("/tracking") 
                }
                store.enqueueSnackbar({
                    message: error.response.data.message,
                    options: {
                        variant: 'error',
                    },
                });
            }
        ).catch((error) => {
            store.enqueueSnackbar({
                message: t('error.unknown'),
                options: {
                    variant: 'error',
                },
            });
            console.log(error)
        })

        
    }

    render() {
        const store = this.props.appStore
        const {t} = this.props

        var trackingLink = "https://www.dhl.de/de/privatkunden/pakete-empfangen/verfolgen.html?piececode="+store.tracking.label.shipmentNumber
        
        var firstColor = "disabled"
        var secondColor = "disabled"
        var thirdColor = "disabled"
        var fourthColor = "disabled"

        if(store.tracking.returnStatus === "PRE_TRANSIT") {
            firstColor = "primary"
        } else if(store.tracking.returnStatus === "TRANSIT") {
            firstColor = "primary"
            secondColor = "primary"
        } else if(store.tracking.returnStatus === "DELIVERED") {
            firstColor = "primary"
            secondColor = "primary"
            thirdColor = "primary"
        } else if(store.tracking.returnStatus === "FINISHED") {
            firstColor = "primary"
            secondColor = "primary"
            thirdColor = "primary"
            fourthColor = "primary"
        }

        if(this.state.isLoading) {
            return <div>loading</div>
        } else {
            return (
                <div className="container">
                    <Container>
                        <Grid 
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                        >
                            <Typography component="h1" variant="h4">
                                {t('tracking.heading')}
                            </Typography>
                            <Typography className="mb-16" component="p" variant="subtitle1">
                                {t('tracking.text-2')}
                            </Typography>

                            <Grid container direction="row" justify="center" alignItems="center">
                                <Grid item>
                                    <Typography component="span" variant="h2">
                                        <CloudUploadRounded fontSize="inherit" color={firstColor} />
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography component="span" variant="h2">
                                        <FlightTakeoff fontSize="inherit" color={secondColor} />
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography component="span" variant="h2">
                                        <FlightLand fontSize="inherit" color={thirdColor} />
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography component="span" variant="h2">
                                        <MonetizationOn fontSize="inherit" color={fourthColor} />
                                    </Typography>
                                </Grid>
                            </Grid>
                            
                            <a className="link mt-16" href={trackingLink} target="__blank">
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                >
                                    {t('tracking.button-dhl')}
                                </Button>
                            </a>
                            
                            <a className="link mt-16" href={"data:application/pdf;base64,"+store.tracking.label.labelData} download="return.pdf">
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                >
                                    {t('label.button-download')}
                                </Button>
                            </a>
                        </Grid>
                    </Container>
                </div>
            )
        }
        
    }
}
export default withSnackbar(withTranslation('common')(TrackingStatus))
