import React, { Component } from 'react'
import { Container, Typography, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody
, Select, MenuItem, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Slide, CircularProgress, Grid, Box } from '@material-ui/core'
import { Link } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import history from '../../history';

import { getArticlesOverviewByOrder, abortReturn, createLabel } from '../../utils/APIUtils'
import { withSnackbar } from 'notistack';
import { getSafe, isAuthenticated, isValidReturn } from '../../utils/Utils';
import { withTranslation } from 'react-i18next';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

@inject("appStore")
@observer
class Overview extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            isLoading: true,
            isOpen: false,
            submitDisabled: false,
        }

        this.getArticles() 
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    }

    componentDidMount() {
        const store = this.props.appStore
        const {t} = this.props
        var orderResponse = store.orderResponse
        var articles = store.articles

        if(!isAuthenticated(orderResponse)) {
            history.push("/")
            store.enqueueSnackbar({
                message: t('error.no-access'),
                options: {
                    variant: 'error',
                },
            });
        } 
   
    }

    getArticles = () => {
        const store = this.props.appStore
        const {t} = this.props
        var internalId = getSafe(() => store.orderResponse.id, -1)
        var postcode = getSafe(() => store.orderResponse.postcode, -1)
        

        getArticlesOverviewByOrder({internalOrderId: internalId, postcode: postcode}, store.lang).then(
            result => {
                var data = result.data
                store.resetArticles()
                store.setArticles(data)
                this.setState({isLoading:false})

                if(!isValidReturn(store.articles)) {
                    history.push("/article")
                    store.enqueueSnackbar({
                        message: t('error.invalid-data'),
                        options: {
                            variant: 'error',
                        },
                    });
                } 
            },
            error => {
                if(error.response.data.returnToHome) {
                    history.push("/") 
                }
                store.enqueueSnackbar({
                    message: error.response.data.message,
                    options: {
                        variant: 'error',
                    },
                });
            }
        ).catch(
            error => {
                store.enqueueSnackbar({
                    message: t('error.unknown'),
                    options: {
                        variant: 'error',
                    },
                });
                console.log(error)
            }
        )
    }

    handleOnClickBack = (e) => {
        history.goBack()
    }

    abort = () => {
        const store = this.props.appStore
        const {t} = this.props
        var request = { internalOrderId : store.orderResponse.id, postcode: store.orderResponse.postcode }

        abortReturn(request, store.lang).then(
            result => {
                console.log(result.data)
                history.push("/")
            },
            error => {
                if(error.response.data.returnToHome) {
                    history.push("/") 
                }
                store.enqueueSnackbar({
                    message: error.response.data.message,
                    options: {
                        variant: 'error',
                    },
                });
            }
        ).catch(
            error => {
                store.enqueueSnackbar({
                    message: t('error.unknown'),
                    options: {
                        variant: 'error',
                    },
                });
                console.log(error)
            }
        )
    }

    handleButtonSubmit = () => {
        if(this.state.submitDisabled) {
            return
        }

        this.setState({submitDisabled:true})

        const store = this.props.appStore
        const {t} = this.props
        var request = { internalOrderId : store.orderResponse.id, postcode: store.orderResponse.postcode }

        createLabel(request, store.lang).then(
            result => {
                store.setLabel(result.data)
                history.push("/label")
                store.enqueueSnackbar({
                    message: t('success.return-created'),
                    options: {
                        variant: 'success',
                    },
                });
                this.setState({submitDisabled:false})
            },
            error => {
                if(error.response.data.returnToHome) {
                    history.push("/") 
                }
                store.enqueueSnackbar({
                    message: error.response.data.message,
                    options: {
                        variant: 'error',
                    },
                });
                this.setState({submitDisabled:false})
            }
        ).catch(
            error => {
                store.enqueueSnackbar({
                    message: t('error.unknown'),
                    options: {
                        variant: 'error',
                    },
                });
                console.log(error)
                this.setState({submitDisabled:false})
            }
        )
    }

    handleClickOpen = () => {
        this.setState({isOpen: true});
    };
    
    handleCloseYes = () => {
        this.setState({isOpen: false});
        this.abort()
    };

    handleCloseNo= () => {
        this.setState({isOpen: false});
    };

    render() {
        const store = this.props.appStore;
        const {t} = this.props
        var isLoading = this.state.isLoading

        var buttonCondition;
        if(this.state.submitDisabled) {
            buttonCondition = <CircularProgress/>
        } else {
            buttonCondition = t('overview.button-correct')
        }
        
        if(isLoading) {
            return (
                <div className="container">
                    <Container>
                        <Grid 
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                        > 
                            <CircularProgress/>
                        </Grid>
                    </Container>
                </div>
            )
        } else {
            return (
                <div className="container">
                        <Container>
                            <div className="mb-16">
                                <Typography component="h1" variant="h5">
                                    {t('overview.heading')}
                                </Typography>
                                <Typography component="h1" variant="h6">
                                    {t('overview.text')}
                                </Typography>
                            </div>
                            <TableContainer component={Paper}>
                            <Table aria-label="table">
                                <TableHead>
                                <TableRow>
                                <TableCell>{t('article.table-number-of-returns')}</TableCell>
                                <TableCell align="right">{t('article.table-article')}</TableCell>
                                <TableCell align="right">{t('article.table-price')}</TableCell>
                                <TableCell align="right">{t('article.table-reason')}</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {store.articles.map((row, index) => (
                                    <TableRow key={row.id}>
                                    <TableCell align="right">
                                        <Select 
                                            disabled
                                            labelId="number-of-return" 
                                            id="number-of-return" 
                                            value={row.numberOfReturns}>
                                            {Array.from(Array(row.quantity+1).keys()).map((row, index) => (
                                                <MenuItem key={index} value={index}>{index}</MenuItem>
                                            ))}
                                        </Select>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.articleName}
                                    </TableCell>
                                    <TableCell align="right">{row.price}</TableCell>
                                    <TableCell align="right">
                                        <Select 
                                            disabled
                                            labelId="number-of-return" 
                                            id="number-of-return" 
                                            value={row.returnReason}
                                        >
                                        <MenuItem value={"NOCH_NICHTS"}>{t('article.select-nothing')}</MenuItem>
                                        <MenuItem value={"BRUCH"}>{t('article.select-broken')}</MenuItem>
                                        <MenuItem value={"KRATZER"}>{t('article.select-scratch')}</MenuItem>
                                        <MenuItem value={"SCHLECHTE_QUALITAET"}>{t('article.select-quality')}</MenuItem>
                                        <MenuItem value={"FALSCHE_LIEFERUNG"}>{t('article.select-delivery')}</MenuItem>
                                        <MenuItem value={"SONSTIGES"}>{t('article.select-na')}</MenuItem>
                                        </Select>
                                    </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                            </TableContainer>
                            <Box className="mt-32" display="flex" justifyContent="space-between" flexDirection="row-reverse">
                                <Link className="link" to="/overview">
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        onClick={this.handleButtonSubmit}
                                        disabled={this.state.submitDisabled}
                                    >
                                        {buttonCondition}
                                    </Button>
                                </Link>
                                <Link className="link" to="/overview">
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="secondary"
                                        onClick={this.handleOnClickBack}
                                        disabled={this.state.submitDisabled}
                                    >
                                        {t('overview.button-back')}
                                    </Button>
                                </Link>
                            </Box>
                            {/**<div className="mt-32">
                                <Link className="link" to="/overview">
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="secondary"
                                        onClick={this.handleClickOpen}
                                        disabled={this.state.submitDisabled}
                                    >
                                        {t('article.button-cancel')}
                                    </Button>
                                </Link>
                                            </div> */}
                        </Container>
                        <Dialog
                        open={this.state.isOpen}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={this.handleClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title">{t('article.cancel-dialog-heading')}</DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            {t('article.cancel-dialog-text')}
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={this.handleCloseYes} color="primary">
                            {t('yes')}
                        </Button>
                        <Button onClick={this.handleCloseNo} color="primary">
                            {t('no')}
                        </Button>
                        </DialogActions>
                    </Dialog>
                    </div>
            )
        }
        
    }
} export default withSnackbar(withTranslation('common')(Overview))